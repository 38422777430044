import { render, staticRenderFns } from "./GroupDataView.vue?vue&type=template&id=59dd09fe&scoped=true&"
import script from "./GroupDataView.vue?vue&type=script&lang=ts&"
export * from "./GroupDataView.vue?vue&type=script&lang=ts&"
import style0 from "./GroupDataView.vue?vue&type=style&index=0&id=59dd09fe&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59dd09fe",
  null
  
)

export default component.exports